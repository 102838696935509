import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import qs from 'qs';
import {config} from '../config';
import {accessToken} from '../configuration/tokenHandling/accessToken';
import {AccessToken} from '../configuration/tokenHandling/tokenSlice';

const prepareHeaders = (headers: Headers) => {
    const token = accessToken.getAccessToken() as AccessToken;
    if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
    }
    return headers;
};

// Define a service using a base URL and expected endpoints
export const nasysResultStateApi = createApi({
    reducerPath: 'nasysResult',
    tagTypes: ['NasysResultState'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DEVICE_INIT_MONITOR_SERVICE,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        fetchNasysResultStates: builder.query<any, {}>({
            query: () => ({
                url: '/devices/info',
                responseHandler: async (response: Response) => {
                    const data = await response.json();
                    return data;
                },
            }),
            providesTags: ['NasysResultState'],
        }),
    }),
});

export const quarterlyDeviceRegistrationStateApi = createApi({
    reducerPath: 'quarterlyDeviceRegistrationResult',
    tagTypes: ['QuarterlyDeviceRegistrationResultState'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DEVICE_SERVICE,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        fetchQuarterOneStates: builder.query<any, {
            start: number;
            end: number;
        }>({
            query: ({start, end}) => ({
                url: `/connectivity-stats?start=${start}&end=${end}`,
                responseHandler: async (response: Response) => {
                    const data = await response.json();
                    return data;
                },
            }),
            providesTags: ['QuarterlyDeviceRegistrationResultState'],
        }),
        fetchQuarterTwoStates: builder.query<any, {
            start: number;
            end: number;
        }>({
            query: ({start, end}) => ({
                url: `/connectivity-stats?start=${start}&end=${end}`,
                responseHandler: async (response: Response) => {
                    const data = await response.json();
                    return data;
                },
            }),
            providesTags: ['QuarterlyDeviceRegistrationResultState'],
        }),
        fetchQuarterThreeStates: builder.query<any, {
            start: number;
            end: number;
        }>({
            query: ({start, end}) => ({
                url: `/connectivity-stats?start=${start}&end=${end}`,
                responseHandler: async (response: Response) => {
                    const data = await response.json();
                    return data;
                },
            }),
            providesTags: ['QuarterlyDeviceRegistrationResultState'],
        }),
        fetchQuarterFourStates: builder.query<any, {
            start: number;
            end: number;
        }>({
            query: ({start, end}) => ({
                url: `/connectivity-stats?start=${start}&end=${end}`,
                responseHandler: async (response: Response) => {
                    const data = await response.json();
                    return data;
                },
            }),
            providesTags: ['QuarterlyDeviceRegistrationResultState'],
        }),
        fetchAmountRegisteredDevicesStates: builder.query<any, {
            start: number;
            end: number;
        }>({
            query: ({start, end}) => ({
                url: `/connectivity-stats?start=${start}&end=${end}`,
                responseHandler: async (response: Response) => {
                    const data = await response.json();
                    return data;
                },
            }),
            providesTags: ['QuarterlyDeviceRegistrationResultState'],
        }),
    }),
});

export const quarterlyCM4DeviceRegistrationStateApi = createApi({
    reducerPath: 'quarterlyCM4DeviceRegistrationResult',
    tagTypes: ['QuarterlyCM4DeviceRegistrationResultState'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DEVICE_SERVICE_CM4,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        fetchCM4QuarterOneStates: builder.query<any, {
            start: number;
            end: number;
        }>({
            query: ({start, end}) => ({
                url: `/connectivity-stats?start=${start}&end=${end}`,
                responseHandler: async (response: Response) => {
                    const data = await response.json();
                    return data;
                },
            }),
            providesTags: ['QuarterlyCM4DeviceRegistrationResultState'],
        }),
        fetchCM4QuarterTwoStates: builder.query<any, {
            start: number;
            end: number;
        }>({
            query: ({start, end}) => ({
                url: `/connectivity-stats?start=${start}&end=${end}`,
                responseHandler: async (response: Response) => {
                    const data = await response.json();
                    return data;
                },
            }),
            providesTags: ['QuarterlyCM4DeviceRegistrationResultState'],
        }),
        fetchCM4QuarterThreeStates: builder.query<any, {
            start: number;
            end: number;
        }>({
            query: ({start, end}) => ({
                url: `/connectivity-stats?start=${start}&end=${end}`,
                responseHandler: async (response: Response) => {
                    const data = await response.json();
                    return data;
                },
            }),
            providesTags: ['QuarterlyCM4DeviceRegistrationResultState'],
        }),
        fetchCM4QuarterFourStates: builder.query<any, {
            start: number;
            end: number;
        }>({
            query: ({start, end}) => ({
                url: `/connectivity-stats?start=${start}&end=${end}`,
                responseHandler: async (response: Response) => {
                    const data = await response.json();
                    return data;
                },
            }),
            providesTags: ['QuarterlyCM4DeviceRegistrationResultState'],
        }),
        fetchAmountRegisteredCM4DevicesStates: builder.query<any, {
            start: number;
            end: number;
        }>({
            query: ({start, end}) => ({
                url: `/connectivity-stats?start=${start}&end=${end}`,
                responseHandler: async (response: Response) => {
                    const data = await response.json();
                    return data;
                },
            }),
            providesTags: ['QuarterlyCM4DeviceRegistrationResultState'],
        }),
    }),
});

// Export hooks for usage in function components which are
export const {useFetchNasysResultStatesQuery} = nasysResultStateApi;
export const {
    useFetchAmountRegisteredDevicesStatesQuery,
    useFetchQuarterOneStatesQuery,
    useFetchQuarterTwoStatesQuery,
    useFetchQuarterThreeStatesQuery,
    useFetchQuarterFourStatesQuery
} = quarterlyDeviceRegistrationStateApi;
export const {
    useFetchAmountRegisteredCM4DevicesStatesQuery,
    useFetchCM4QuarterOneStatesQuery,
    useFetchCM4QuarterTwoStatesQuery,
    useFetchCM4QuarterThreeStatesQuery,
    useFetchCM4QuarterFourStatesQuery
} = quarterlyCM4DeviceRegistrationStateApi;
