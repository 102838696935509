import React, { useEffect, useState } from 'react';
import BarChart from '@rio-cloud/rio-uikit/BarChart';
import Bar from '@rio-cloud/rio-uikit/Bar';
import {
    useFetchCM4QuarterFourStatesQuery,
    useFetchCM4QuarterOneStatesQuery,
    useFetchCM4QuarterThreeStatesQuery,
    useFetchCM4QuarterTwoStatesQuery,
    useFetchQuarterFourStatesQuery,
    useFetchQuarterOneStatesQuery,
    useFetchQuarterThreeStatesQuery,
    useFetchQuarterTwoStatesQuery,
} from '../../../../../../services/overviewStateApi';
import moment from 'moment/moment';
import size from 'lodash/fp/size';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { DEVICE_TYPE } from '../../../device-sidebar/device-state.types';

const getQuarterTimeRanges = (year: number) => {
    return {
        Q1: {
            start: moment(year, 'YYYY').quarter(1).startOf('quarter').unix() * 1000,
            end: moment(year, 'YYYY').quarter(1).endOf('quarter').unix() * 1000
        },
        Q2: {
            start: moment(year, 'YYYY').quarter(2).startOf('quarter').unix() * 1000,
            end: moment(year, 'YYYY').quarter(2).endOf('quarter').unix() * 1000
        },
        Q3: {
            start: moment(year, 'YYYY').quarter(3).startOf('quarter').unix() * 1000,
            end: moment(year, 'YYYY').quarter(3).endOf('quarter').unix() * 1000
        },
        Q4: {
            start: moment(year, 'YYYY').quarter(4).startOf('quarter').unix() * 1000,
            end: moment(year, 'YYYY').quarter(4).endOf('quarter').unix() * 1000
        },
    };
};

interface BackendDevicesGraphProps {
    deviceType: string[];
}

export const BackendDevicesGraph= (backendDevicesGraphProps: BackendDevicesGraphProps) => {
    const [year, setYear] = useState(2025);
    const [data, setData] = useState<any[]>();
    const { deviceType } = backendDevicesGraphProps;

    const quarterTimeRanges = getQuarterTimeRanges(year);

    const {data: amountQuarterOne, isFetching: isFetchingQuarterOne} = useFetchQuarterOneStatesQuery(
        {
            start: quarterTimeRanges.Q1.start,
            end: quarterTimeRanges.Q1.end
        },
    );
    const {data: amountQuarterOneCM4, isFetching: isFetchingQuarterOneCM4} = useFetchCM4QuarterOneStatesQuery(
        {
            start: quarterTimeRanges.Q1.start,
            end: quarterTimeRanges.Q1.end
        },
    );
    const {data: amountQuarterTwo, isFetching: isFetchingQuarterTwo} = useFetchQuarterTwoStatesQuery(
        {
            start: quarterTimeRanges.Q2.start,
            end: quarterTimeRanges.Q2.end
        },
    );
    const {data: amountQuarterTwoCM4, isFetching: isFetchingQuarterTwoCM4} = useFetchCM4QuarterTwoStatesQuery(
        {
            start: quarterTimeRanges.Q2.start,
            end: quarterTimeRanges.Q2.end
        },
    );
    const {data: amountQuarterThree, isFetching: isFetchingQuarterThree} = useFetchQuarterThreeStatesQuery(
        {
            start: quarterTimeRanges.Q3.start,
            end: quarterTimeRanges.Q3.end
        },
    );
    const {data: amountQuarterThreeCM4, isFetching: isFetchingQuarterThreeCM4} = useFetchCM4QuarterThreeStatesQuery(
        {
            start: quarterTimeRanges.Q3.start,
            end: quarterTimeRanges.Q3.end
        },
    );
    const {data: amountQuarterFour, isFetching: isFetchingQuarterFour} = useFetchQuarterFourStatesQuery(
        {
            start: quarterTimeRanges.Q4.start,
            end: quarterTimeRanges.Q4.end
        },
    );
    const {data: amountQuarterFourCM4, isFetching: isFetchingQuarterFourCM4} = useFetchCM4QuarterFourStatesQuery(
        {
            start: quarterTimeRanges.Q4.start,
            end: quarterTimeRanges.Q4.end
        },
    );

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const data: any[] = [
            {quarter: 'Q1', amount: 0},
            {quarter: 'Q2', amount: 0},
            {quarter: 'Q3', amount: 0},
            {quarter: 'Q4', amount: 0}
        ];
        if (amountQuarterOne !== undefined && deviceType?.includes(DEVICE_TYPE.TBM3)) {
            data[0].amount += amountQuarterOne;
        }
        if (amountQuarterOneCM4 !== undefined && deviceType?.includes(DEVICE_TYPE.CM4)) {
            data[0].amount += amountQuarterOneCM4;
        }
        if (amountQuarterTwo !== undefined && deviceType?.includes(DEVICE_TYPE.TBM3)) {
            data[1].amount += amountQuarterTwo;
        }
        if (amountQuarterTwoCM4 !== undefined && deviceType?.includes(DEVICE_TYPE.CM4)) {
            data[1].amount += amountQuarterTwoCM4;
        }
        if (amountQuarterThree !== undefined && deviceType?.includes(DEVICE_TYPE.TBM3)) {
            data[2].amount += amountQuarterThree;
        }
        if (amountQuarterThreeCM4 !== undefined && deviceType?.includes(DEVICE_TYPE.CM4)) {
            data[2].amount += amountQuarterThreeCM4;
        }
        if (amountQuarterFour !== undefined && deviceType?.includes(DEVICE_TYPE.TBM3)) {
            data[3].amount += amountQuarterFour;
        }
        if (amountQuarterFourCM4 !== undefined && deviceType?.includes(DEVICE_TYPE.CM4)) {
            data[3].amount += amountQuarterFourCM4;
        }
        setData(data);
    }, [amountQuarterOne,
        amountQuarterTwo,
        amountQuarterThree,
        amountQuarterFour,
        amountQuarterOneCM4,
        amountQuarterTwoCM4,
        amountQuarterThreeCM4,
        amountQuarterFourCM4,
        deviceType]);

    return (
        <>
            {size(data) < 4 ||
            isFetchingQuarterOne ||
            isFetchingQuarterTwo ||
            isFetchingQuarterThree ||
            isFetchingQuarterFour ||
            isFetchingQuarterOneCM4 ||
            isFetchingQuarterTwoCM4 ||
            isFetchingQuarterThreeCM4 ||
            isFetchingQuarterFourCM4 ? (
                <>
                    <Spinner isDoubleSized text="Loading" />
                </>
            ) : (
                <>
                    <div className="display-flex justify-content-around margin-bottom-20">
                        <div className="col-md-6">
                            <a onClick={() => setYear(2022)}>
                                <div className={year === 2022 ? 'text-bold text-decoration-underline' : ''}>2022</div>
                            </a>
                        </div>
                        <div className="col-md-6">
                            <a onClick={() => setYear(2023)}>
                                <div className={year === 2023 ? 'text-bold text-decoration-underline' : ''}>2023</div>
                            </a>
                        </div>
                        <div className="col-md-6">
                            <a onClick={() => setYear(2024)}>
                                <div className={year === 2024 ? 'text-bold text-decoration-underline' : ''}>2024</div>
                            </a>
                        </div>
                        <div className="col-md-6">
                            <a onClick={() => setYear(2025)}>
                                <div className={year === 2025 ? 'text-bold text-decoration-underline' : ''}>2025</div>
                            </a>
                        </div>
                    </div>
                    <BarChart
                        data={data}
                        dataKey="quarter"
                        margin={{ top: 25, bottom: 25 }}
                        tooltip={false}
                        bars={[
                            <Bar
                                key="bar"
                                dataKey="amount"
                                label={{
                                    position: 'top',
                                    formatter: (value: any) => `${value}`,
                                }}
                            />,
                        ]}
                    />
                </>
            )}
        </>
    );
};
