import DatePicker from '@rio-cloud/rio-uikit/DatePicker';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { Moment } from 'moment/moment';
import React, { useEffect, useState } from 'react';
import {
    useFetchAmountRegisteredDevicesStatesQuery,
    useFetchAmountRegisteredCM4DevicesStatesQuery
} from '../../../../../../services/overviewStateApi';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { DEVICE_TYPE } from '../../../device-sidebar/device-state.types';

interface AmountBackendDevicesProps {
    deviceType: string[];
}

export const AmountBackendDevices= (amountBackendDevicesProps: AmountBackendDevicesProps) => {
    const { formatMessage, locale } = useIntl();
    const [startDateTime, setStartDateTime] = useState(moment().subtract(1, 'day').unix() * 1000);
    const [endDateTime, setEndDateTime] = useState(moment().unix() * 1000);
    const [rangeButton, setRangeButton] = useState('lastDay');
    const [data, setData] = useState<number>();
    const { deviceType } = amountBackendDevicesProps;

    const { data: amountTBM3, isFetching: isFetchingTBM3 } = useFetchAmountRegisteredDevicesStatesQuery({
        start: startDateTime,
        end: endDateTime,
    });
    const { data: amountCM4, isFetching: isFetchingCM4 } = useFetchAmountRegisteredCM4DevicesStatesQuery({
        start: startDateTime,
        end: endDateTime,
    });

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        let data: number = 0;
        if (amountTBM3 !== undefined && deviceType?.includes(DEVICE_TYPE.TBM3)) {
            data += amountTBM3;
        }
        if (amountCM4 !== undefined && deviceType?.includes(DEVICE_TYPE.CM4)) {
            data += amountCM4;
        }
        setData(data);
    }, [amountTBM3, amountCM4, deviceType]);

    const onTimeRangeChange = (start: Moment, end: Moment, button: string) => {
        setStartDateTime(start.unix() * 1000);
        setEndDateTime(end.unix() * 1000);
        setRangeButton(button);
    };

    const handleFromDatePickerChange = (date: any) => {
        setStartDateTime(date.unix() * 1000);
        setRangeButton('');
        if (date > endDateTime) {
            setEndDateTime(date.unix() * 1000);
        }
    };

    const handleToDatePickerChange = (date: any) => {
        setEndDateTime(date.unix() * 1000);
        setRangeButton('');
    };

    const validFrom = (current: any) => {
        return current < moment();
    };
    const validTo = (current: any) => {
        return startDateTime < current && current < moment();
    };
    return (
        <>
            <div className="display-flex gap-10 margin-bottom-15">
                <Button
                    disabled={isFetchingTBM3 || isFetchingCM4}
                    active={rangeButton === 'lastDay'}
                    onClick={() => onTimeRangeChange(moment().subtract(1, 'day'), moment(), 'lastDay')}
                >
                    {formatMessage({
                        id: 'intl-msg:coreconAdminWeb.datePicker.last24Hours',
                    })}
                </Button>
                <Button
                    disabled={isFetchingTBM3 || isFetchingCM4}
                    active={rangeButton === 'lastWeek'}
                    onClick={() => onTimeRangeChange(moment().subtract(1, 'week'), moment(), 'lastWeek')}
                >
                    {formatMessage({
                        id: 'intl-msg:coreconAdminWeb.datePicker.lastWeek',
                    })}
                </Button>
                <Button
                    disabled={isFetchingTBM3 || isFetchingCM4}
                    active={rangeButton === 'lastMonth'}
                    onClick={() => onTimeRangeChange(moment().subtract(1, 'month'), moment(), 'lastMonth')}
                >
                    {formatMessage({
                        id: 'intl-msg:coreconAdminWeb.datePicker.lastMonth',
                    })}
                </Button>
                <Button
                    disabled={isFetchingTBM3 || isFetchingCM4}
                    active={rangeButton === 'lastYear'}
                    onClick={() => onTimeRangeChange(moment().subtract(1, 'year'), moment(), 'lastYear')}
                >
                    {formatMessage({
                        id: 'intl-msg:coreconAdminWeb.datePicker.lastYear',
                    })}
                </Button>
            </div>
            <div className="display-flex gap-10 margin-bottom-10">
                <div className="flex-1-1-0">
                    <label>{formatMessage({ id: 'intl-msg:coreconAdminWeb.dateRangePicker.from' })}:</label>
                    <DatePicker
                        value={startDateTime}
                        inputProps={{ disabled: isFetchingTBM3 || isFetchingCM4 }}
                        dateFormat="DD/MM/YYYY"
                        timeFormat="HH:mm"
                        isValidDate={validFrom}
                        onChange={handleFromDatePickerChange}
                        locale={locale}
                    />
                </div>
                <div className="flex-1-1-0">
                    <label>{formatMessage({ id: 'intl-msg:coreconAdminWeb.dateRangePicker.to' })}:</label>
                    <DatePicker
                        value={endDateTime}
                        inputProps={{ disabled: isFetchingTBM3 || isFetchingCM4 }}
                        dateFormat="DD/MM/YYYY"
                        timeFormat="HH:mm"
                        isValidDate={validTo}
                        onChange={handleToDatePickerChange}
                        locale={locale}
                    />
                </div>
            </div>
            <div className="display-grid place-items-center height-100">
                {isFetchingTBM3 || isFetchingCM4 ? (
                    <Spinner isDoubleSized text="Loading" />
                ) : (
                    <p className="text-bold text-size-h1"> {data || '--'} </p>
                )}
            </div>
        </>
    );
};
