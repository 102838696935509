import { useIntl } from 'react-intl';
import React, { useState } from 'react';
import { ValueWithHint } from './components/ValueWithHint';
import { useFetchNasysResultStatesQuery } from '../../../../services/overviewStateApi';
import { BackendDevicesGraph } from './components/BackendDevices/Graph';
import { AmountBackendDevices } from './components/BackendDevices/AmountBackendDevices';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { DEVICE_TYPE } from '../device-sidebar/device-state.types';

export const OverviewHome = () => {
    const { formatMessage } = useIntl();
    const [deviceType, setDeviceType] = useState(['TBM3', 'CM4']);
    const { data, isLoading } = useFetchNasysResultStatesQuery({}, {
        pollingInterval: 10000,
    });

    let percentForSuccess = 0;
    let percentForNoSuccess = 0;
    let percentForReplaced = 0;
    let percentForLost = 0;

    if (data) {
        percentForSuccess = data.successDevices / data.total * 100;
        percentForNoSuccess = data.noSuccessDevices / data.total * 100;
        percentForReplaced = data.replacedDevices / data.total * 100;
        percentForLost = data.lostDevices / data.total * 100;
    }

    const selectDeviceType = (type: DEVICE_TYPE) => {
        setDeviceType((prevDeviceType) => {
            if (prevDeviceType.includes(type)) {
                return prevDeviceType.filter((item) => item !== type);
            } else {
                return [...prevDeviceType, type];
            }
        });
    };

    return (
        <>
            <h1>{formatMessage({ id: 'intl-msg:coreconAdminWeb.overview.nasysResults' })}</h1>
            <div className="panel panel-default">
                <div className="panel-body">
                    <ul className="display-flex justify-content-around list-inline list-unstyled">
                        <li className="col-sm-3">
                            <ValueWithHint
                                isLoading={isLoading}
                                value={data?.successDevices}
                                valueColor="text-primary"
                                unit={` (${percentForSuccess.toFixed(2)}%)`}
                                unitColor="text-primary"
                                hint={formatMessage({
                                    // eslint-disable-next-line max-len
                                    id: 'intl-msg:coreconAdminWeb.overview.nasysResults.successfullySigned',
                                })}
                            />
                        </li>
                        <li className="col-sm-3">
                            <ValueWithHint
                                isLoading={isLoading}
                                value={data?.noSuccessDevices}
                                valueColor="text-warning"
                                unit={` (${percentForNoSuccess.toFixed(2)}%)`}
                                unitColor="text-warning"
                                hint={formatMessage({
                                    // eslint-disable-next-line max-len
                                    id: 'intl-msg:coreconAdminWeb.overview.nasysResults.notSigned',
                                })}
                            />
                        </li>
                        <li className="col-sm-3">
                            <ValueWithHint
                                isLoading={isLoading}
                                value={data?.replacedDevices}
                                valueColor="text-warning"
                                unit={` (${percentForReplaced.toFixed(2)}%)`}
                                unitColor="text-warning"
                                hint={formatMessage({
                                    // eslint-disable-next-line max-len
                                    id: 'intl-msg:coreconAdminWeb.overview.nasysResults.replaced',
                                })}
                            />
                        </li>
                        <li className="col-sm-3">
                            <ValueWithHint
                                isLoading={isLoading}
                                value={data?.lostDevices}
                                valueColor="text-danger"
                                unit={` (${percentForLost.toFixed(2)}%)`}
                                unitColor="text-danger"
                                hint={formatMessage({
                                    // eslint-disable-next-line max-len
                                    id: 'intl-msg:coreconAdminWeb.overview.nasysResults.lost',
                                })}
                            />
                        </li>
                    </ul>
                </div>
            </div>
            <hr />
            <h1>{formatMessage({ id: 'intl-msg:coreconAdminWeb.overview.registeredDevices' })}</h1>
            <div className="panel panel-default">
                <div className="panel-body">
                    <div className="display-flex">
                        <Button
                            className="margin-right-10"
                            active={deviceType.includes(DEVICE_TYPE.TBM3)}
                            onClick={() => selectDeviceType(DEVICE_TYPE.TBM3)}
                            disabled={deviceType.length === 1 && deviceType.includes(DEVICE_TYPE.TBM3)}
                        >
                            {formatMessage({ id: 'intl-msg:coreconAdminWeb.deviceSigning.tbm3' })}
                        </Button>
                        <Button
                            active={deviceType.includes(DEVICE_TYPE.CM4)}
                            onClick={() => selectDeviceType(DEVICE_TYPE.CM4)}
                            disabled={deviceType.length === 1 && deviceType.includes(DEVICE_TYPE.CM4)}
                        >
                            {formatMessage({ id: 'intl-msg:coreconAdminWeb.deviceSigning.cm4' })}
                        </Button>
                    </div>
                    <hr />
                    <ul className="display-flex justify-content-center list-inline list-unstyled">
                        <li className="col-sm-6 margin-right-20">
                            <label className="text-size-h5">
                                {formatMessage({ id: 'intl-msg:coreconAdminWeb.overview.registeredDevices.quarterly' })}
                            </label>
                            <div className="panel panel-default panel-body margin-bottom-0 height-300 width-500">
                                <BackendDevicesGraph deviceType={deviceType} />
                            </div>
                        </li>
                        <li className="col-sm-6 margin-left-20">
                            <label className="text-size-h5">
                                {formatMessage({ id: 'intl-msg:coreconAdminWeb.overview.registeredDevices.select' })}
                            </label>
                            <div className="panel panel-default panel-body margin-bottom-0 height-300 width-500">
                                <AmountBackendDevices deviceType={deviceType} />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};
